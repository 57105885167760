"use client";

import { Box, Flex, Separator } from "@radix-ui/themes";
import React from "react";
import { isIterable } from "rxjs/internal/util/isIterable";


type TypeParams = Readonly<{
  children?: React.ReactNode;
}>;


function joinChildren(children: Iterable<React.ReactNode>): React.ReactNode {
  const list: Array<React.ReactNode> = [];
  let i = 0;
  for (const child of children) {
    list.push(child);
    list.push(<Separator key={i++} orientation="vertical" size="2" />);
  }
  list.pop();
  return list;
}


export default function HeaderWrapper({ children }: TypeParams) {
  return (
    <Box  key="wrapper" className="Header">
      <Flex  key="row" direction="row" gap="2" align={"center"} justify={"start"}>
        <div  key="start" />
        {isIterable(children) ? joinChildren(children) : children}
        <div  key="end" />
      </Flex>
      <Separator  key="botom" size="4" />
    </Box>
  );
}

