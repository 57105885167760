"use client";

import { api } from "@/client/Api";
import { StoredVar } from "@/client/lib/StoredVar";
import { AuthResult, AvatarData } from "@/client/types";
import { ITelegramUser } from "@/types";
import { jwtDecode, JwtHeader, JwtPayload } from "jwt-decode";
import { once } from "remeda";
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  from,
  Observable,
  of,
  retry,
  shareReplay,
  switchMap,
} from "rxjs";


class Auth {
  private _authorized$ = new BehaviorSubject<boolean | undefined>(undefined);
  private _authorized: boolean | undefined;

  public readonly accessToken = new StoredVar<string>("auth.accessToken", "persistent");
  public readonly refreshToken = new StoredVar<string>("auth.refreshToken", "persistent");
  public readonly authorized$ = this._authorized$.pipe(
    // fullLog('authorized raw'),
    filter(val => val !== undefined),
    distinctUntilChanged(),
    // fullLog('authorized distinct'),
    shareReplay({
      refCount: false,
      bufferSize: 1
    }),
    // fullLog('authorized shared'),
  );


  public readonly avatar$: Observable<AvatarData | null> = this.authorized$.pipe(
    switchMap((auth) => auth ? this.getAvatar : of(null))
  );

  private getAvatar: Observable<AvatarData | null> = from(api.getAvatar()).pipe(
    retry({count: 3, delay: 1000})
  );


  constructor() {
    // let all fields construct
    setTimeout(()=>{
      this.wakeUp();
      this.update().then();
    }, 0);
    setInterval(() => this.update(), 30_000);
  }


  // ========================================================================
  //              PUBLIC METHODS
  // ========================================================================

  public async start(): Promise<boolean> {
    if (this.authorized !== undefined) {
      return this.authorized;
    } else {
      return firstValueFrom(this.authorized$);
    }
  };

  public get authorized(): boolean | undefined {
    return this._authorized$.value;
  }


  public get tokenData(): JwtPayload | null {
    const rawToken = this.accessToken.value;
    if (rawToken) {
      return jwtDecode(rawToken);
    }
    return null;
  }

  public get tokenHeader(): JwtHeader | null {
    const rawToken = this.accessToken.value;
    if (rawToken) {
      return jwtDecode(rawToken, { header: true });
    }
    return null;
  }

  // noinspection JSUnusedGlobalSymbols
  public async loginTg(userData: ITelegramUser): Promise<void> {
    try {
      const auth = await api.authByTelegram(userData);
      this.setTokens(auth);
    } catch (e) {
      this.setTokens(null);
      console.error("Error during token refresh", e);
      throw e;
    }
  }

  public logout() {
    this.setTokens(null);
  }

  // ========================================================================
  //              INNER LOGIC
  // ========================================================================

  private wakeUp() {
    if (this.accessToken.value) {
      if (this.isAccessTokenValid()) {
        this._authorized$.next(true);
        return;
      }
    }
    this._authorized$.next(false);
  }

  private async update() {
    if (this.accessToken.value) {
      const byInternalTimout = true; // TODO: refresh timeout in config
      const byTokenValidity = !this.isAccessTokenValid();
      const needToUpdate = byInternalTimout || byTokenValidity;
      const required = byTokenValidity;

      if (needToUpdate) {
        const oldRefreshToken = this.refreshToken.value;
        const newTokens = oldRefreshToken
          ? await api.refreshTokens(oldRefreshToken)
          : null;
        if (newTokens || required) {
          this.setTokens(newTokens);
        }
      }
    }
    this.syncState();
  }

  private async loadRefresh(oldRefreshToken: string): Promise<AuthResult | null> {
    try {
      return await api.refreshTokens(oldRefreshToken);
    } catch (e) {
      console.error("Error during token refresh", e);
    }
    return null;
  }

  private setTokens(tokens: AuthResult | null) {
    if (tokens) {
      this.accessToken.value = tokens.accessToken;
      this.refreshToken.value = tokens.refreshToken;
    } else {
      this.accessToken.value = null;
      this.refreshToken.value = null;
    }
    this.syncState();
  }

  private syncState() {
    this._authorized$.next(!!this.accessToken.value);
  }

  private isAccessTokenValid() {
    const data = this.tokenData;
    if (!data) {
      console.warn("token empty");
      return false;
    }
    if (!data.exp) {
      console.warn("token exp empty");
      return false;
    }
    if (data.exp >= Date.now()) {
      console.warn("token expired");
      return false;
    }
    return true;
  }


  fakeLogin() {
    this.setTokens({
      "accessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjUsImlhdCI6MTczMDUyMTQ1OCwiZXhwIjoxNzMwNjA3ODU4fQ.1nskVvXWopQKqXH8MCgx35arqSXEL8JAZhqfhfnOjIY",
      "refreshToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjUsImlhdCI6MTczMDUyMTQ1OCwiZXhwIjoxNzMzMTEzNDU4fQ.cJw_X3QQyrDRyxy2fZ2mEAHsBJfo-h2rTWcgKTZoRro",
    });
  }
}

const create = once(() => new Auth());

export function auth() {
  return create();


}