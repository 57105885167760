"use client";

import { Box, TabNav } from "@radix-ui/themes";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";


type TypeParams = Readonly<{
  children?: React.ReactNode;
}>;

export default function MainMenu({ }: TypeParams) {
  const pathname = usePathname();



  return (
    <Box>
    <TabNav.Root>
      <Link  href="/"  passHref legacyBehavior>
        <TabNav.Link active={pathname === '/'}>
          Главная
        </TabNav.Link>
      </Link>
      <Link href="/search" passHref legacyBehavior>
        <TabNav.Link  active={pathname === '/search'}>
          Поиск
        </TabNav.Link>
      </Link>
      <Link href="/about" passHref legacyBehavior>
      <TabNav.Link active={pathname === '/about'}>
        Как это работает
      </TabNav.Link>
      </Link>
    </TabNav.Root>
    </Box>
  );
}

