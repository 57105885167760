"use client";
import { auth } from "@/client/Auth";
import { AuthResult, AvatarData } from "@/client/types";
import {
  BasicAiResponseDTO,
  ChannelFullDTO,
  GetSearchDTO,
  PaginatedList,
  PaginatedMessageList,
  PaginationRequestDTO,
  RepeatStartDTO,
  SearchChannelDTO,
  SearchRequestFullDTO,
  SearchRequestShortDTO,
  SearchStartDTO,
  StartResultDTO, StaticPagination,
} from "@/client/types/api.types";
import { ITelegramUser } from "@/types";

import axios, { AxiosInstance } from "axios";
import { from, lastValueFrom, repeat } from "rxjs";


function buildAxios() {
  const inst: AxiosInstance = axios.create({
    baseURL: process.env.API_HOST,
    timeout: 10_000,
    headers: { "X-Custom-Header": "foobar" },
  });
  // console.log('API HOST',  process.env.API_HOST);

  inst.interceptors.request.use(function(config) {
    const token = auth().accessToken.value;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  });

  return inst;
}

type SearchResultsList = PaginatedList<SearchChannelDTO, StaticPagination>;
type SearchHistoryList = PaginatedList<SearchRequestShortDTO, StaticPagination>;

class Api {

  private baseUrl = null;
  private axios: AxiosInstance = buildAxios();


  async authByTelegram(userData: ITelegramUser): Promise<AuthResult> {
    const { data } = await this.axios.post(`/auth/tg`, userData);
    return data;
  }

  async refreshTokens(token: string): Promise<AuthResult> {
    const req = this.axios.post(`/auth/refresh`, { token });
    const req$ = from(req).pipe(repeat({ count: 3, delay: 5000 }));
    const { data } = await lastValueFrom(req$);
    return data;
  }


  async startSearch(params: SearchStartDTO): Promise<StartResultDTO> {
    const { data } = await this.axios.post<StartResultDTO>("/search/", params);
    return data;
  }

  async repeatSearch(params: RepeatStartDTO): Promise<StartResultDTO> {
    const { data } = await this.axios.post<StartResultDTO>("/search/repeat", params);
    return data;
  }

  async getSearch(params: GetSearchDTO): Promise<SearchRequestFullDTO> {
    const { data } = await this.axios.get<SearchRequestFullDTO>(`/search/${params.hash}`);
    return data;
  }

  async getSearchResults(hash: string, pagination: PaginationRequestDTO): Promise<SearchResultsList> {
    const { data } = await this.axios.get<SearchResultsList>(`/search/${hash}/results`, {
      params: {
        ...pagination,
      },
    });
    return data;
  }

  async getSearchHistory(params: PaginationRequestDTO): Promise<SearchHistoryList> {
    const { data } = await this.axios.get<SearchHistoryList>(`/search/`, {
      params,
    });
    return data;
  }


  async getChannelInfo(hash: string): Promise<ChannelFullDTO> {
    const { data } = await this.axios.get<ChannelFullDTO>(`/channels/${hash}`);
    return data;
  }


  async getAvatar(): Promise<AvatarData> {
    const { data } = await this.axios.get<AvatarData>(`/user/avatar`);
    return data;

  }

  async getMessages(hash: string): Promise<PaginatedMessageList> {
    const { data } = await this.axios.get<PaginatedMessageList>(`/channels/${hash}/messages`);
    return data;
  }

  async getAiBasic(hash: string): Promise<BasicAiResponseDTO> {
    const { data } = await this.axios.get<BasicAiResponseDTO>(`/channels/${hash}/ai/basic`);
    return data;
  }

}


export const api = new Api();