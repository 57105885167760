"use client";

import { auth } from "@/client/Auth";
import { ExitIcon, PersonIcon } from "@radix-ui/react-icons";
import { Avatar, Flex, IconButton, Text } from "@radix-ui/themes";
import React from "react";
import { useObservable } from "react-use";


type TypeParams = Readonly<{
  children?: React.ReactNode;
}>;

export default function AvatarWidget({ }: TypeParams) {
  const avatar = useObservable(auth().avatar$);
  if (!avatar){
    return null;
  }
  return (
    <Flex align={"center"} gap="2">
      <Avatar
        radius="full"
        fallback=<PersonIcon/>
        // src={avatar.photo}
        title={avatar.name}
      />
      <Text as="div" size="2" weight="bold">
        {avatar.name}
      </Text>
      <IconButton variant="ghost" onClick={() => auth().logout()}>
        <ExitIcon width="18" height="18" />
      </IconButton>
    </Flex>
  );
}

