"use client";

import { distinctUntilChanged, Observable, shareReplay, Subject } from "rxjs";

declare global {
  interface Window {
    sessionStorage: Storage,
    localStorage: Storage,
  }
}


type StorageType = "persistent" | "session";

export interface StorageConverter<T> {
  write: (input: T) => string,
  read: (input: string) => T,
}


export class StoredVar<T extends string> {

  private readonly storage?: Storage;
  public readonly key: string;

  private readonly _value$: Subject<T|null> = new Subject<T|null>();
  public readonly value$: Observable<T|null> = this._value$.pipe(
    distinctUntilChanged(),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  constructor(
    key: string,
    type: StorageType = "persistent",
  ) {
    this.key = key;
    if (typeof window !== "undefined") {
      this.storage = type === "session" ? window.sessionStorage : window.localStorage;
    }
    this._value$.next(this.value);
  }

  public set value(value: T | null) {
    if (!this.storage) return;
    if (value) {
      this.storage.setItem(this.key, value);
      this._value$.next(value);
    } else {
      this.storage.removeItem(this.key);
      this._value$.next(null);
    }
  }

  public get value(): T | null {
    if (!this.storage) return null;
    return this.storage.getItem(this.key) as T | null;
  }
}